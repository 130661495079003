import React from 'react'
import styles from "./index.module.scss"
import {Link} from "gatsby";
import 'lazysizes';


class ToolCard extends React.Component {


    render() {
        const {src, link, title, description} = this.props;
        return (
            <div className={styles.toolCard}>
                <Link to={link} style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                }}>
                    <img data-src={src} className="lazyload" alt={title}/>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </Link>
            </div>
        )
    }
}

export default ToolCard