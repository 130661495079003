import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import styles from './index.module.scss'
import Teaser from '../components/Teaser'
import ToolCard from '../components/ToolCard'
import 'lazysizes';
import Layout from '../components/Layout'
import GetStartedOrChat from '../components/CTA/GetStartedOrChat'

import aspectRatioDesktop from "../images/pages/index/aspect-ratio-desktop.jpg"
import aspectRatioMobile from "../images/pages/index/aspect-ratio-mobile.jpg"
class BlogIndex extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setAsLead = this.setAsLead.bind(this);
  }

  setAsLead() {
    if (window.gtag) {
      gtag('event', 'conversion', {
        send_to: 'AW-864469722/D5heCKWfzZQBENqFm5wD',
      })
    }

    if (window.ga) {
      ga('send', 'event', 'form', 'submit', 'lead')
    }

    // If the facebook pixel is loaded, track the user as a lead
    if (window.fbq) {
      window.fbq('track', 'Lead')
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setAsLead();
    location.replace(`https://app.echowave.io/register?email=${this.state.value}`);
  }

  render() {
    return (
      <Layout wide>
        <SEO
          title="Share audio with engaging Videos"
          description="Amplify your podcast, by converting into waveform video to share on on Facebook, Twitter and Instagram"
          keywords={[`EchoWave`, `Share audio to facebook`]}
        />

        {/* HERO */}
        <div className={styles.hero}>
          <div className={styles.intro}>

            <section>
            <h1 id="title" className={styles.title}>
              Share your Podcast with <strong>engaging video!</strong>
            </h1>
            <h2 id="subtitle" className={styles.subTitle}>
              EchoWave transforms your Podcast or Audio into videos with
              one click.
            </h2>
            <div>
                <div className={styles.intro__form}>
                  <form onSubmit={this.handleSubmit}>
                    <input
                      className={styles.intro__email}
                      type="email"
                      placeholder="Your E-Mail"
                      name="EMAIL"
                      value={this.state.value}
                      onChange={this.handleChange}
                      autoFocus
                    />
                    <div
                      style={{ position: 'absolute', left: '-5000px' }}
                      aria-hidden="true"
                    >
                    </div>
                    <input
                      className={styles.intro__btn}
                      type="submit"
                      value="Get Started →"
                      name="subscribe"
                    />
                  </form>
                </div>
            </div>
            </section>
          </div>
          <div className={styles.ocean}>
            <div className={styles.wave} />
            <div className={styles.wave} />
          </div>
        </div>

        <section className={styles.content}>
        <div className={styles.main}>
          {/*Social Proof Section*/}
          <div className={styles.socialProof}>
            <h2 className={styles.headerTitleCenter}>
              Creators are leveraging <b>EchoWave.io</b> to share audio on
              Facebook, Instagram, and Youtube.
            </h2>
            <p className={styles.centerSmall}>
              Some examples from podcasts we love, they are not using EchoWave
              yet, but hope they will be soon.
            </p>
            <div className={styles.teasers}>
              <Teaser
                preview="99pi.jpg"
                previewGif="99pi.gif"
                videoLocation="99pi.mp4"
              />
              <Teaser
                preview="thisamericanlife.png"
                previewGif="thisamaricanlife.gif"
                videoLocation="thisamaricanlife.mp4"
              />
              <Teaser
                preview="lore.jpg"
                previewGif="lore.gif"
                videoLocation="lore.mp4"
              />
              <Teaser
                preview="everythingisalive.jpg"
                previewGif="everythingisalive.gif"
                videoLocation="everythingisalive.mp4"
              />
            </div>
          </div>


          <div className={styles.ourOffer}>
            <div className={styles.ourOfferIntro}>
              <h2 className={styles.headerTitle}>
                Retarget your audio content
              </h2>
              <p>
                EchoWave allows you to repurpose your audio by <b>converting sound files to video</b>, without downloading and
                installing software. Enabling you to <b>increase your social reach and drive engagement.</b>
              </p>

              <p>
                Our platform puts you in charge, allowing your videos to match your brand identity.
              </p>
            </div>

            <div className={styles.ourOfferUseCases}>
              <div className={styles.ourOfferUseCase}>
                <img
                  className={styles.ourOfferUseCaseIcon}
                  src="/images/index/podcast.svg"
                  alt="Podcast Icon"
                />
                <h3 className={styles.ourOfferUseCaseTitle}>
                  Made For Podcasters
                </h3>
                <p className={styles.blurb}>
                  Captivate listeners and Increase your podcasts reach by
                  sharing videos on social: Echowave converts Podcast snippets
                  or whole episodes into video with waveform in one click.
                </p>
                <a className={styles.hideLink} href="#">
                  <button className={styles.getStartedButton}>
                    Convert podcasts to video
                  </button>
                </a>
              </div>
              <div className={styles.ourOfferUseCase}>
                <img
                  className={styles.ourOfferUseCaseIcon}
                  src="/images/index/record.svg"
                  alt="Record Icon"
                />
                <h3 className={styles.ourOfferUseCaseTitle}>
                  Perfect For Musicians
                </h3>
                <p className={styles.blurb}>
                  Unleash your music on every social platform, connect directly
                  with your fans by converting your music sound file to a video
                  with customisable cover image and waveforms.
                </p>
                <a className={styles.hideLink} href="#">
                  <button className={styles.getStartedButton}>
                    Convert music to video
                  </button>
                </a>
              </div>
            </div>



            <div>

              <h2>Multiple Aspect Ratios</h2>
              <p>Maximise your video to the full potential, we support square, horizontal and vertical aspect ratios to match you social media needs. Perfect for publishing clips or full length videos.</p>
              <img data-src={aspectRatioMobile} className="lazyload" alt="Aspect Ratios"/>

            </div>

          </div>

          <div className={styles.howItWorks}>
            <div className={styles.howItWorksBackground}>
              <h2 className={styles.headerTitle}>How It Works.</h2>
              <p>
                It only takes <strong>3 Simple Steps </strong> to convert sound
                files to elegant video:
              </p>
              <ol className={styles.main}>
                <li>Upload your sound file</li>
                <li>Select or upload your cover picture</li>
                <li>
                  Submit &{' '}
                  <b>
                    <i>We take care of the rest</i>
                  </b>
                </li>
              </ol>
              <p>Your video is available for download, or sharing. DONE</p>
              <h2 className={styles.headerTitle}>Mission Statement</h2>
              <p>
                Creators we have a problem; we can't upload our audio to the
                most popular social networks, we miss out by not sharing our
                best work. EchoWave was founded to solve the audio sharability
                problem. With EchoWave you can convert sound to video enabling
                you to upcycle your excellent content on social. EchoWave
                allows you to upload audio to Facebook, Twitter and Instagram
                with an easy to use self-service interface. Convert your audio
                to videos now and start getting 10x engagement.
              </p>
              <a className={styles.hideLink} href="#">
                <button className={styles.getStartedButton}>
                  Request Access
                </button>
              </a>
            </div>
          </div>



          <section className={styles.social}>
            <h2 className={styles.headerTitle}>
              Support for every social network
            </h2>
            <h3 className={styles.headerTitle}>
              Export videos in square, vertical and horizontal aspect ratios,
              perfect for every video platform.
            </h3>
            <div>
              <img
                data-src="/images/socialLogos/Instagram.svg"
                alt="Instagram Logo"
                className="lazyload"
              />
              <img data-src="/images/socialLogos/Facebook.svg" alt="Facebook Logo" className="lazyload"/>
              <img data-src="/images/socialLogos/YouTube.svg" alt="YouTube Logo" className="lazyload"/>
              <img data-src="/images/socialLogos/Twitter.svg" alt="Twitter Logo" className="lazyload"/>
              <img data-src="/images/socialLogos/Snapchat.svg" alt="Snapchat Logo" className="lazyload"/>
              <img
                data-src="/images/socialLogos/Pinterest.svg"
                alt="Pinterest Logo"
                className="lazyload"
              />
              <img data-src="/images/socialLogos/LinkedIn.svg" alt="LinkedIn Logo" className="lazyload"/>
              <img data-src="/images/socialLogos/Medium.svg" alt="Medium Logo" className="lazyload"/>
              <img data-src="/images/socialLogos/VKcom.svg" alt="VK.com Logo" className="lazyload"/>
            </div>
            <img
              data-src="/images/index/socialStrategy.svg"
              alt="Social Growth Strategy"
              className="lazyload"
            />
          </section>


          {/*<h3>Related Guides</h3>*/}
          <div className={styles.guides}>


            <ToolCard
              link="/videocasts/"
              src="/images/videocast.jpg"
              title="Video Podcasts"
              description="In recent years, audio podcasts have taken the world by storm, but video podcasts are also growing in popularity."
            />

            <ToolCard
              link="/podcast-to-video/"
              src="/images/podcast-coffee.jpg"
              title="Echowave Guide"
              description="Step by Step walk-though of converting Audio Files into sharable Podcast videos"
            />

            <ToolCard
              link="/podcast-questions-list/"
              src="/images/podcast-questions-list.jpg"
              title="Podcast Question List"
              description="List of interesting podcast questions to ask your guest"
            />

            <ToolCard
              link='/how-to-interview-for-a-podcast/'
              src='/images/podcast-interview.jpg'
              title='How To Interview for a Podcast'
              description='Get the best content from your guest interview'
            />

            <ToolCard
              link='/how-to-post-your-podcast-on-youtube/'
              src='/images/podcast-into-video.jpg'
              title='How to post your podcast on youtube'
              description='Make your podcast avaliable on video platforms like youtube'
            />
            <ToolCard
              link='/remote-podcast-recording/'
              src='/images/talking.jpg'
              title='Remote Podcast Recording'
              description='Missing Body Language while doing Remote Podcast.'
            />


          </div>
        </div>
        </section>

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
